module.exports = {
  "/docs/webhook-behavior":"/docs/webhooks/webhook-behavior/",
  "/docs/webhooks-v1-overview":"/docs/webhooks/v1-overview/",
  "/docs/webhooks-mutual-tls":"/docs/webhooks/webhooks-mutual-tls/",
  "/docs/libraries":"/docs/tools-libraries/client-libraries/",
  "/docs/tools-projects":"/docs/tools-libraries/tools-projects/",
  "/docs/retrieve-incident-details-using-the-rest-api":"/docs/tools-libraries/retrieve-incident-details/",
  "/docs/rest-api":"/docs/rest-api-v2/rest-api/",
  "/docs/versioning":"/docs/rest-api-v2/versioning/",
  "/docs/authentication":"/docs/rest-api-v2/authentication/",
  "/docs/rate-limiting":"/docs/rest-api-v2/rate-limiting/",
  "/docs/endpoints":"/docs/rest-api-v2/endpoints/",
  "/docs/types":"/docs/rest-api-v2/types/",
  "/docs/filtering":"/docs/rest-api-v2/filtering/",
  "/docs/sorting":"/docs/rest-api-v2/sorting/",
  "/docs/pagination":"/docs/rest-api-v2/pagination/",
  "/docs/resource-schemas":"/docs/rest-api-v2/resource-schemas/",
  "/docs/references":"/docs/rest-api-v2/references/",
  "/docs/wrapped-entities":"/docs/rest-api-v2/wrapped-entities/",
  "/docs/incident-creation-api":"/docs/rest-api-v2/incident-creation-api/",
  "/docs/errors":"/docs/rest-api-v2/errors/",
  "/docs/includes":"/docs/rest-api-v2/includes/",
  "/docs/global-event-rules-api":"/docs/rest-api-v2/global-event-rules-api/",
  "/docs/api-v2-frequently-asked-questions":"/docs/rest-api-v1/api-v2-frequently-asked-questions/",
  "/docs/v1-rest-api-decommissioning-faq":"/docs/rest-api-v1/v1-rest-api-decommissioning-faq/",
  "/docs/migrating-to-api-v2":"/docs/rest-api-v1/migrating-to-api-v2/",
  "/docs/v1-rest-api-integrations-update":"/docs/rest-api-v1/v1-rest-api-integrations-update/",
  "/docs/getting-started":"/docs/get-started/getting-started/",
  "/docs/events-api-v2":"/docs/events-api-v2/overview/",
  "/docs/send-an-event-events-api-v2":"/docs/events-api-v2/trigger-events/",
  "/docs/events-api":"/docs/events-api-v1/overview/",
  "/docs/trigger-events":"/docs/events-api-v1/trigger-events/",
  "/docs/cet":"/docs/events-api-v1/custom-event-transformer/",
  "/docs/app-overview":"/docs/app-integration-development/app-overview/",
  "/docs/developer-account":"/docs/app-integration-development/developer-account/",
  "/docs/how-to-build-an-app":"/docs/app-integration-development/register-an-app/",
  "/docs/developing-a-pagerduty-integration":"/docs/app-integration-development/events-integration/",
  "/docs/api-picker":"/docs/app-integration-development/api-picker/",
  "/docs/app-functionality":"/docs/app-integration-development/app-functionality/",
  "/docs/oauth-2-functionality":"/docs/f59fdbd94ceab-o-auth-functionality",
  "/docs/oauth-2-functionality-pkce":"/docs/a708c20fb57e5-obtaining-a-user-o-auth-token-via-pkce",
  "/docs/oauth-2-functionality-client-secret":"/docs/3ec0d67458b0b-obtaining-a-user-o-auth-token-via-code-grant",
  "/docs/oauth-2-functionality-app-token": "/docs/e518101fde5f3-obtaining-an-app-o-auth-token",
  "/docs/oauth-2-revoke-tokens": "/docs/f59fdbd94ceab-o-auth-functionality",
  "/docs/publish-your-app":"/docs/app-integration-development/publish-your-app/",
  "/docs/webhooks-v2-overview":"/docs/webhooks/v2-overview/",
  "/page/developer-agreement":"/docs/legal/developer-agreement/",
  "/page/sample-code-disclaimer":"/docs/legal/sample-code-disclaimer/",
  //
  //
  "/docs/app-integration-development/api-picker/": "/docs/ZG9jOjExMDI5NTcx-api-pickers",
  "/docs/app-integration-development/app-functionality/": "/docs/ZG9jOjExMDI5NTcw-app-functionality-overview",
  "/docs/app-integration-development/app-overview/": "/docs/ZG9jOjExMDI5NTY3-app-overview",
  "/docs/app-integration-development/developer-account/": "/docs/ZG9jOjExMDI5NTY4-developer-account-faq",
  "/docs/app-integration-development/events-integration/": "/docs/ZG9jOjExNTQzMjI5-events-integration-functionality",
  "/docs/app-integration-development/oauth-2-auth-code-grant/": "/docs/3ec0d67458b0b-obtaining-a-user-o-auth-token-via-code-grant",
  "/docs/app-integration-development/oauth-2-functionality/": "/docs/f59fdbd94ceab-o-auth-functionality",
  "/docs/app-integration-development/oauth-2-pkce/": "/docs/a708c20fb57e5-obtaining-a-user-o-auth-token-via-pkce",
  "/docs/app-integration-development/publish-your-app/": "/docs/ZG9jOjExMDI5NTc2-publish-an-app",
  "/docs/app-integration-development/register-an-app/": "/docs/ZG9jOjExMDI5NTY5-register-an-app",
  "/docs/events-api-v1/custom-event-transformer/": "/docs/ZG9jOjExMDI5NTc5-custom-event-transformer",
  "/docs/events-api-v1/overview/": "/docs/ZG9jOjExMDI5NTc3-events-api-v1-overview",
  "/docs/events-api-v1/trigger-events/": "/docs/ZG9jOjExMDI5NTc4-send-a-v1-event",
  "/docs/events-api-v2/overview/": "/docs/ZG9jOjExMDI5NTgw-events-api-v2-overview",
  "/docs/events-api-v2/trigger-events/": "/docs/ZG9jOjExMDI5NTgx-send-an-alert-event",
  "/docs/events-api-v2/send-change-events/": "/docs/ZG9jOjExMDI5NTgy-send-a-change-event",
  "/docs/events-api-v2/custom-change-event-transformer/": "/docs/ZG9jOjExMDI5NTgz-custom-change-event-transformer",
  "/docs/get-started/getting-started/": "/docs/ZG9jOjQ2NDA2-introduction",
  "/docs/legal/developer-agreement/": "/docs/ZG9jOjExMDI5NTg1-pager-duty-developer-agreement",
  "/docs/legal/sample-code-disclaimer/": "/docs/ZG9jOjExMDI5NTg0-sample-code-disclaimer",
  "/docs/rest-api-v2/authentication/": "/docs/ZG9jOjExMDI5NTUx-authentication",
  "/docs/rest-api-v2/endpoints/": "/docs/ZG9jOjExMDI5NTU0-endpoints",
  "/docs/rest-api-v2/errors/": "/docs/ZG9jOjExMDI5NTYz-errors",
  "/docs/rest-api-v2/filtering/": "/docs/ZG9jOjExMDI5NTU2-filtering",
  "/docs/rest-api-v2/global-event-rules-api/": "/docs/ZG9jOjExMDI5NTY1-global-event-rules-api",
  "/docs/rest-api-v2/incident-creation-api/": "/docs/ZG9jOjExMDI5NTY0-incident-creation-api",
  "/docs/rest-api-v2/includes/": "/docs/ZG9jOjExMDI5NTYy-includes",
  "/docs/rest-api-v2/pagination/": "/docs/ZG9jOjExMDI5NTU4-pagination",
  "/docs/rest-api-v2/rate-limiting/": "/docs/72d3b724589e3-rest-api-rate-limits",
  "/docs/rest-api-v2/references/": "/docs/ZG9jOjExMDI5NTYw-resource-references",
  "/docs/rest-api-v2/resource-schemas/": "/docs/ZG9jOjExMDI5NTU5-resource-schemas",
  "/docs/rest-api-v2/rest-api/": "/docs/ZG9jOjExMDI5NTUw-rest-api-overview",
  "/docs/rest-api-v2/sorting/": "/docs/ZG9jOjExMDI5NTU3-sorting",
  "/docs/rest-api-v2/types/": "/docs/ZG9jOjExMDI5NTU1-types",
  "/docs/rest-api-v2/versioning/": "/docs/ZG9jOjExMDI5NTUy-versioning", 
  "/docs/rest-api-v2/wrapped-entities/": "/docs/ZG9jOjExMDI5NTYx-wrapped-entities",
  "/docs/rest-api-v2/audit-records-api/": "/docs/ZG9jOjExMDI5NTY2-audit-records-api",
  "/docs/tools-libraries/client-libraries/": "/docs/ZG9jOjExMDI5NTg2-api-client-libraries",
  "/docs/tools-libraries/retrieve-incident-details/": "/docs/ZG9jOjExMDI5NTg5-retrieving-incident-details",
  "/docs/tools-libraries/tools-projects/": "/docs/ZG9jOjExMDI5NTg3-api-tools-and-code-samples",
  "/docs/tools-libraries/pagerduty-agent/": "/docs/ZG9jOjExMDI5NTg4-pager-duty-on-prem-agent",
  "/docs/webhooks/v1-overview/": "/docs/ZG9jOjQ1MjA5ODc2-overview-v1-webhooks",
  "/docs/webhooks/v2-overview/": "/docs/ZG9jOjQ1MjA5ODc1-overview-v2-webhooks",
  "/docs/webhooks/webhook-behavior/": "/docs/ZG9jOjExMDI5NTkx-behaviour",
  "/docs/webhooks/webhooks-mutual-tls/": "/docs/ZG9jOjExMDI5NTky-mutual-tls",
  "/docs/webhooks/v3-overview/": "/docs/ZG9jOjQ1MTg4ODQ0-overview",
  "/docs/webhooks/webhook-signatures/": "/docs/ZG9jOjExMDI5NTkz-verifying-signatures",
  "/docs/webhooks/webhook-certificates/": "/docs/ZG9jOjE4NzkyNjM1-public-certificates",
  "/docs/webhooks/safelist-ips/": "/docs/ZG9jOjQ4NDA3OTUw-safelist-i-ps"
}
