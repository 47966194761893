import React from 'react';

import Layout from '../components/layout';
import { Link } from 'gatsby';
import { StoplightProject } from "@stoplight/elements-dev-portal";
const devDocRedirects = require(`./../../dev-doc-redirect`)

require('@stoplight/elements/styles.min.css');

export default class ApiReference extends React.Component {
  
  constructor(props) {
    super(props);
  }


  render() {
    const renderStopLight = () => {
      const pathString = this.props.location.pathname.toLowerCase();
      const isRenderingStoplightDisabled = devDocRedirects[pathString];
      if (! isRenderingStoplightDisabled) {
        return <StoplightProject
        projectId={process.env.GATSBY_STOPLIGHT_DOCS_PROJECT_ID}
        basePath="/docs"
        router={typeof window === 'undefined' ? 'memory' : 'history'}
        />
      }
    }

    return  (
        <Layout>
          {renderStopLight()}
          <footer className="container p-gut h-gut text-gray-dark mb-gut">
            See a typo, mistake, or missing content? You can {" "}
            <a href="https://github.com/PagerDuty/developer-docs"
                  className="text-green hover:bg-yellow">
              contribute to this documention on Github.
            </a>
          </footer>
        </Layout>
      )
    }
}
